import { gql } from '@apollo/client';

export const GET_PURCHASED_PHONE_NUMBERS = gql`
  query PurchasedNumberAccounts(
    $filter: IdentityTwilioNumberAccountFilter
    $search: IdentityTwilioNumberAccountSearch
  ) {
    identity {
      admin {
        twilio {
          purchasedNumberAccounts(filter: $filter, search: $search) {
            freeToUse
            phoneNumber
            friendlyName
            dateCreated
            capabilities {
              fax
              voice
              sms
              mms
            }
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
